/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Button, Card, CardContent, Snackbar, Stack, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function LiveContests() {
    const navigate = useNavigate()
    const handleRoute = (url) => {
        navigate('/' + url)
    }

    const [contests, setContests] = useState([])
    const [upcomingContests, setUpcomingContests] = useState([])
    const [liveContests, setLiveContests] = useState([])
    const [completedContests, setCompletedContests] = useState([])
    const [hiddenContests, setHiddenContests] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    const getContestsList = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/user/contests`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setLiveContests(data.data.liveContests.reverse())
                    setUpcomingContests(data.data.upcomingContests)
                    setApiCalled(true)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const updateCompletedStatusOfQuiz = (id, status) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ completed: status })
        }

        fetch(`${BASE_URL}/api/admin/contest/complete/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    getContestsList()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const handleEditClick = (id) => {
        navigate('/edit-contest-v2/' + id)
    }

    const handleDetailsClick = (id) => {
        navigate('/contest-details/' + id)
    }

    const openLeaderboardScreen = (id) => {
        navigate('/contest-leaderboard-v2/' + id)
    }

    const openParticipantsScreen = (id) => {
        navigate('/contest-participants/' + id)
    }

    const openSubmissionsScreen = (id) => {
        navigate('/contest-submissions/' + id)
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const openCancelContestPage = (id, name) => {
        navigate(`/cancel-contest?id=${id}&name=${name}`)
    }

    useEffect(() => {
        if (!apiCalled) {
            getContestsList()
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='p-4 grid grid-cols-1 md:grid-cols-1 gap-4'>
                        <div>
                            {liveContests.length > 0 && <h1 className='text-xl font-bold text-gray-700'>Live Contests</h1>}
                            {liveContests.length > 0 &&
                                liveContests.map((contest, index) => {
                                    return (
                                        <Card key={index} className='my-2' sx={{ backgroundColor: 'whitesmoke' }}>
                                            <CardContent>
                                                <Typography variant='h6' component='div'>
                                                    {contest.name}
                                                    {' • '}
                                                    {contest.description}
                                                </Typography>
                                                <Typography variant='body2' color='text.secondary'>
                                                    Started: {showTimeInAgoFormat(contest.startDate)}
                                                </Typography>
                                                <div>
                                                    <Button variant='contained' color='success' onClick={() => handleEditClick(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                        Edit
                                                    </Button>
                                                    <Button variant='contained' color='info' onClick={() => openLeaderboardScreen(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                        Leaderboard
                                                    </Button>
                                                    <Button variant='contained' color='info' onClick={() => openSubmissionsScreen(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                        Submissions
                                                    </Button>
                                                    <Button variant='contained' color='warning' onClick={() => updateCompletedStatusOfQuiz(contest._id, true)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                        Mark as Complete
                                                    </Button>
                                                    <Button variant='contained' color='error' onClick={() => openCancelContestPage(contest._id, contest.name)} sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    )
                                })}
                            {liveContests.length === 0 && <h1 className='text-2xl mt-10 text-center text-gray-700'>No contest available</h1>}
                        </div>
                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                                <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                    Success!!!
                                </Alert>
                            </Snackbar>
                        </Stack>

                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                                <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                    Error!!!
                                </Alert>
                            </Snackbar>
                        </Stack>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default LiveContests
