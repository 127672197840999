/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Question() {
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        navigate('/' + route)
    }
    return (
        <div className='flex bg-gray-900 text-white'>
            <div className='relative flex flex-col bg-clip-border bg-gray-700 text-gray-400 h-[calc(100vh)] w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5'>
                <div className='mb-2 p-4'>
                    <h5 className='block antialiased tracking-normal font-sans text-xl font-semibold leading-snug'>Admin Dashboard</h5>
                </div>
                <nav className='flex flex-col gap-1 min-w-[240px] p-2 font-sans text-base font-normal'>
                    <div role='button' onClick={() => handleNavigation('')} tabindex='0' className='flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-blue-200 hover:bg-opacity-80 focus:bg-blue-200 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80 hover:text-blue-900 focus:text-blue-900 active:text-blue-900 outline-none'>
                        <div className='grid place-items-center mr-4'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' aria-hidden='true' className='h-5 w-5'>
                                <path fill-rule='evenodd' d='M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.04 16.5l.5-1.5h6.42l.5 1.5H8.29zm7.46-12a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-3 2.25a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9zm-3 2.25a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5z' clip-rule='evenodd'></path>
                            </svg>
                        </div>
                        Home
                    </div>
                    <div role='button' onClick={() => handleNavigation('contests')} tabindex='0' className='flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-blue-200 hover:bg-opacity-80 focus:bg-blue-200 focus:bg-opacity-80 active:bg-gray-50 active:bg-opacity-80 hover:text-blue-900 focus:text-blue-900 active:text-blue-900 outline-none'>
                        <div className='grid place-items-center mr-4'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' aria-hidden='true' className='h-5 w-5'>
                                <path fill-rule='evenodd' d='M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.04 16.5l.5-1.5h6.42l.5 1.5H8.29zm7.46-12a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-3 2.25a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9zm-3 2.25a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5z' clip-rule='evenodd'></path>
                            </svg>
                        </div>
                        Contests
                    </div>
                    <div role='button' onClick={() => handleNavigation('questions')} tabindex='0' className='bg-blue-100 text-gray-800 flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-blue-200 hover:bg-opacity-80 focus:bg-blue-200 focus:bg-opacity-80 active:bg-blue-200 active:bg-opacity-80 hover:text-blue-900 focus:text-blue-900 active:text-blue-900 outline-none'>
                        <div className='grid place-items-center mr-4'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' aria-hidden='true' className='h-5 w-5'>
                                <path fill-rule='evenodd' d='M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z' clip-rule='evenodd'></path>
                            </svg>
                        </div>
                        Questions
                    </div>
                    <div role='button' onClick={() => handleNavigation('users')} tabindex='0' className='flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-blue-200 hover:bg-opacity-80 focus:bg-blue-200 focus:bg-opacity-80 active:bg-blue-200 active:bg-opacity-80 hover:text-blue-900 focus:text-blue-900 active:text-blue-900 outline-none'>
                        <div className='grid place-items-center mr-4'>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' aria-hidden='true' className='h-5 w-5'>
                                <path fill-rule='evenodd' d='M6.912 3a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H6.912zm13.823 9.75l-2.213-7.191A1.5 1.5 0 0017.088 4.5H6.912a1.5 1.5 0 00-1.434 1.059L3.265 12.75H6.11a3 3 0 012.684 1.658l.256.513a1.5 1.5 0 001.342.829h3.218a1.5 1.5 0 001.342-.83l.256-.512a3 3 0 012.684-1.658h2.844z' clip-rule='evenodd'></path>
                            </svg>
                        </div>
                        Users
                    </div>
                </nav>
            </div>
            <div className='flex-grow p-8 bg-gray-800 text-white'>
                <h1 className='my-2 text-3xl font-bold text-blue-500'>Contests</h1>
            </div>
        </div>
    )
}

export default Question
