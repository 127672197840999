import * as React from 'react'
import { useEffect } from 'react'
import { Autocomplete, Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import ReactGA from 'react-ga'
import { formatDateToYYYYMMDD } from '../../Utils/Time'
import { BASE_URL } from '../../Api/Constants'
import { IPL_TEAMS } from '../../Data/Teams'
import DrawerNav from '../DrawerNav/DrawerNav'
import { fetchData } from '../../Api/useFetch'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddContestV2 = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [startDateInputValue, setStartDateInputValue] = useState(formatDateToYYYYMMDD(startDate))
    const [startTime, setStartTime] = useState('09:00')
    const [quizName, setQuizName] = useState('')
    const [entryFees, setEntryFees] = useState(10)
    const [prizePoolMoney, setPrizePoolMoney] = useState(1000)
    const [quizDescription, setQuizDescription] = useState('')
    const [quizTagline, setQuizTagline] = useState('')
    const [seriesName, setSeriesName] = useState('')
    const [teamOne, setTeamOne] = useState('')
    const [teamTwo, setTeamTwo] = useState('')
    const [tableRows, setTableRows] = useState([
        { rank: 1, reward: '', coins: 0.5 * prizePoolMoney },
        { rank: 2, reward: '', coins: 0.3 * prizePoolMoney },
        { rank: 3, reward: '', coins: 0.1 * prizePoolMoney },
        { rank: 4, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 5, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 6, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 7, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 8, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 9, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 10, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 'Others', reward: '', coins: 0.09 * prizePoolMoney }
    ])

    const [teamsList, setTeamsList] = useState([])

    const [questionOne, setQuestionOne] = useState('Who wins match?')
    const [questionTwo, setQuestionTwo] = useState(`${teamOne} Top Batter?`)
    const [questionThree, setQuestionThree] = useState(`${teamTwo} Top Batter?`)
    const [questionFour, setQuestionFour] = useState(`${teamOne} Top Bowler?`)
    const [questionFive, setQuestionFive] = useState(`${teamTwo} Top Bowler?`)
    const [questionOneOptions, setQuestionOneOptions] = useState([
        { value: '', correctScore: 8, incorrectScore: 3 },
        { value: '', correctScore: 5, incorrectScore: 1 },
        { value: 'Draw', correctScore: 10, incorrectScore: 5 }
    ])

    const [questionTwoOptions, setQuestionTwoOptions] = useState([])
    const [questionThreeOptions, setQuestionThreeOptions] = useState([])
    const [questionFourOptions, setQuestionFourOptions] = useState([])
    const [questionFiveOptions, setQuestionFiveOptions] = useState([])

    // First Team Batter
    const testRendererSecondQuestion = (TEAM) => {
        //
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/automation/player/options?role=Batter&team=${TEAM}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setQuestionTwoOptions(data.data.options)
                } else {
                }
            })
    }

    // Second Team Batter
    const testRendererThirdQuestion = (TEAM) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/automation/player/options?role=Batter&team=${TEAM}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setQuestionThreeOptions(data.data.options)
                } else {
                }
            })
    }

    // First Team Bowler
    const testRendererFourthQuestion = (TEAM) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/automation/player/options?role=Bowler&team=${TEAM}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setQuestionFourOptions(data.data.options)
                } else {
                }
            })
    }

    // Second Team Bowler
    const testRendererFifthQuestion = (TEAM) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/automation/player/options?role=Bowler&team=${TEAM}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setQuestionFiveOptions(data.data.options)
                } else {
                }
            })
    }

    const handleOptionOneChange = (index, field, value) => {
        const updatedOptions = [...questionOneOptions]
        updatedOptions[index][field] = value
        setQuestionOneOptions(updatedOptions)
    }

    useEffect(() => {
        const calculateTableRows = () => {
            const firstRankCoins = 0.5 * prizePoolMoney
            const secondRankCoins = 0.3 * prizePoolMoney
            const thirdRankCoins = 0.1 * prizePoolMoney
            const remainingPrizePool = prizePoolMoney - (firstRankCoins + secondRankCoins + thirdRankCoins)

            const updatedTableRows = [
                { rank: 1, reward: '', coins: firstRankCoins },
                { rank: 2, reward: '', coins: secondRankCoins },
                { rank: 3, reward: '', coins: thirdRankCoins },
                { rank: 4, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 5, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 6, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 7, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 8, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 9, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 10, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 'Others', reward: '', coins: remainingPrizePool * 0.01 }
            ]

            setTableRows(updatedTableRows)
        }

        calculateTableRows()
    }, [prizePoolMoney])

    const [rulesRows, setRulesRows] = useState([
        'All Questions are multiple choice.',
        'Every Question will have positive and negative points based on option selection.',
        'Points will be shown against each player while answering question.',
        'Once user clicks on join now button entry will be confirmed.',
        'Once Match Started user cant change their answers.',
        'Minimum 10 users need to join the contest to win iPhone',
        'In order to win iPhone, user must answer all questions correctly.',
        'If user is on table top, but answered only correct questions, that user cant qualify to win iPhone.',
        'If more 2 than users answered all questions correctly, one lucky winner will be selected using draw.',
        'Prizes will be distributed as per winnings table displayed under contest after match completed.',
        'This contest is completely free to join.',
        'If user wins iPhone, user will receive an email with the voucher details with in working days, no need to pay any amount.',
        'Only one entry per person allowed, if multiple entries found for any user, all his entries will be forfeit.',
        'Management decision is final, Management can cancel the contest without any notice at anytime.',
        'Each user can participate in one contest only one time'
    ])

    const addRulesRow = () => {
        const newRows = [...rulesRows, '']
        setRulesRows(newRows)
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setQuizName('')
        setQuizDescription('')
        setEntryFees(10)
    }

    const getMergedDate = (date, time) => {
        const startDate = date
        const startTime = time
        const startDateObject = new Date(startDate)
        const [hours, minutes] = startTime.split(':')
        startDateObject.setHours(hours, minutes, 0, 0)
        const mergedDateTime = startDateObject.getTime()
        return mergedDateTime
    }

    const addNewContest = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: `${teamOne} vs ${teamTwo}`,
                teamOne,
                teamTwo,
                description: seriesName,
                tagline: quizTagline,
                prizePool: prizePoolMoney,
                startDate: getMergedDate(startDate, startTime),
                rewards: tableRows,
                rules: rulesRows,
                entryFees,
                questionsList: [
                    {
                        question: questionOne,
                        options: questionOneOptions
                    },
                    {
                        question: questionTwo,
                        options: questionTwoOptions
                    },
                    {
                        question: questionThree,
                        options: questionThreeOptions
                    },
                    {
                        question: questionFour,
                        options: questionFourOptions
                    },
                    {
                        question: questionFive,
                        options: questionFiveOptions
                    }
                ]
            })
        }

        fetch(`${BASE_URL}/api/admin/v2/contest`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })

        setOpenSuccessAlert()
    }

    const [seriesList, setSeriesList] = useState([])

    const handleTeamsList = (list) => {
        let teams_list = []
        list.forEach((data) => {
            teams_list.push(data.name)
        })
        setTeamsList(teams_list)
    }

    const handleSeriesList = (list) => {
        let series_list = []
        list.forEach((data) => {
            series_list.push(data.name)
        })
        setSeriesList(series_list)
    }

    const fetchTeamsNames = async (series_name) => {
        const data = await fetchData(`api/teams?series=${series_name}`)
        console.log('Teams Name Data : ', data)
        if (data) {
            setApiCalled(true)
            handleTeamsList(data.teamsList)
        }
    }

    const getTeamsList = async (contestId) => {
        const seriesData = await fetchData(`api/series`)
        if (seriesData) {
            handleSeriesList(seriesData.seriesList)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTeamsList()
        }
    })

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='text-4xl font-bold text-gray-750 mt-5'> Add Contest V2</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                value={seriesName}
                                sx={{ width: '75%' }}
                                onChange={(event, newValue) => {
                                    setSeriesName(newValue)
                                    fetchTeamsNames(newValue)
                                }}
                                options={seriesList}
                                renderInput={(params) => <TextField {...params} label='Choose series' variant='outlined' />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                value={teamOne}
                                sx={{ width: '75%' }}
                                onChange={(event, newValue) => {
                                    setTeamOne(newValue)
                                    setQuestionTwo(`${newValue} Top Batter?`)
                                    setQuestionFour(`${newValue} Top Bowler?`)
                                    handleOptionOneChange(0, 'value', newValue)
                                    testRendererSecondQuestion(newValue)
                                    testRendererFourthQuestion(newValue)
                                }}
                                options={teamsList}
                                renderInput={(params) => <TextField {...params} label='Choose Team 1' variant='outlined' />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                value={teamTwo}
                                sx={{ width: '75%' }}
                                onChange={(event, newValue) => {
                                    setTeamTwo(newValue)
                                    setQuestionThree(`${newValue} Top Batter?`)
                                    setQuestionFive(`${newValue} Top Bowler?`)
                                    handleOptionOneChange(1, 'value', newValue)
                                    testRendererThirdQuestion(newValue)
                                    testRendererFifthQuestion(newValue)
                                }}
                                options={teamsList}
                                renderInput={(params) => <TextField {...params} label='Choose Team 2' variant='outlined' />}
                            />
                        </Grid>

                        <hr className='my-8' />

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField disablePortal label='Question 1' id='combo-box-demo' value={questionOne} sx={{ width: '75%' }} onChange={(event, item) => {}} />
                        </Grid>

                        {questionOneOptions.map((option, index) => (
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField disablePortal label='Option' value={option.value} onChange={(event) => handleOptionOneChange(index, 'value', event.target.value)} />
                                <TextField disablePortal label='Correct Score' type='number' value={option.correctScore} onChange={(event) => handleOptionOneChange(index, 'correctScore', event.target.value)} />
                                <TextField disablePortal label='Incorrect Score' type='number' value={option.incorrectScore} onChange={(event) => handleOptionOneChange(index, 'incorrectScore', event.target.value)} />
                            </Grid>
                        ))}

                        <hr className='my-8' />

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                // disabled={true}
                                label='Question 2'
                                id='combo-box-demo'
                                value={questionTwo}
                                sx={{ width: '75%' }}
                                onChange={(event, item) => {
                                    // setQuizTagline(event.target.value)
                                }}
                            />
                        </Grid>

                        {questionTwoOptions.map((option, index) => (
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField disablePortal label='Option' value={option.value} onChange={(event) => handleOptionOneChange(index, 'value', event.target.value)} />
                                <TextField disablePortal label='Correct Score' type='number' value={option.correctScore} onChange={(event) => handleOptionOneChange(index, 'correctScore', event.target.value)} />
                                <TextField disablePortal label='Incorrect Score' type='number' value={option.incorrectScore} onChange={(event) => handleOptionOneChange(index, 'incorrectScore', event.target.value)} />
                            </Grid>
                        ))}

                        <hr className='my-8' />

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                // disabled={true}
                                label='Question 3'
                                id='combo-box-demo'
                                value={questionThree}
                                sx={{ width: '75%' }}
                                onChange={(event, item) => {
                                    // setQuizTagline(event.target.value)
                                }}
                            />
                        </Grid>

                        {questionThreeOptions.map((option, index) => (
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField disablePortal label='Option' value={option.value} onChange={(event) => handleOptionOneChange(index, 'value', event.target.value)} />
                                <TextField disablePortal label='Correct Score' type='number' value={option.correctScore} onChange={(event) => handleOptionOneChange(index, 'correctScore', event.target.value)} />
                                <TextField disablePortal label='Incorrect Score' type='number' value={option.incorrectScore} onChange={(event) => handleOptionOneChange(index, 'incorrectScore', event.target.value)} />
                            </Grid>
                        ))}

                        <hr className='my-8' />

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField disablePortal label='Question 4' id='combo-box-demo' value={questionFour} sx={{ width: '75%' }} onChange={(event, item) => {}} />
                        </Grid>

                        {questionFourOptions.map((option, index) => (
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField disablePortal label='Option' value={option.value} onChange={(event) => handleOptionOneChange(index, 'value', event.target.value)} />
                                <TextField disablePortal label='Correct Score' type='number' value={option.correctScore} onChange={(event) => handleOptionOneChange(index, 'correctScore', event.target.value)} />
                                <TextField disablePortal label='Incorrect Score' type='number' value={option.incorrectScore} onChange={(event) => handleOptionOneChange(index, 'incorrectScore', event.target.value)} />
                            </Grid>
                        ))}

                        <hr className='my-8' />

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField disablePortal label='Question 5' id='combo-box-demo' value={questionFive} sx={{ width: '75%' }} onChange={(event, item) => {}} />
                        </Grid>

                        {questionFiveOptions.map((option, index) => (
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField disablePortal label='Option' value={option.value} onChange={(event) => handleOptionOneChange(index, 'value', event.target.value)} />
                                <TextField disablePortal label='Correct Score' type='number' value={option.correctScore} onChange={(event) => handleOptionOneChange(index, 'correctScore', event.target.value)} />
                                <TextField disablePortal label='Incorrect Score' type='number' value={option.incorrectScore} onChange={(event) => handleOptionOneChange(index, 'incorrectScore', event.target.value)} />
                            </Grid>
                        ))}

                        <hr className='my-8' />

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Tagline'
                                id='combo-box-demo'
                                value={quizTagline}
                                sx={{ width: '75%' }}
                                onChange={(event, item) => {
                                    setQuizTagline(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Entry Fees'
                                type='number'
                                value={entryFees}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '75%' }}
                                onChange={(event, item) => {
                                    setEntryFees(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Prize Pool'
                                type='number'
                                value={prizePoolMoney}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '75%' }}
                                onChange={(event, item) => {
                                    setPrizePoolMoney(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Start Date'
                                type='date'
                                value={startDateInputValue}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '75%' }}
                                onChange={(event, item) => {
                                    setStartDateInputValue(event.target.value)
                                    const myDate = event.target.value.split('-')
                                    const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                                    setStartDate(newDate)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Start Time'
                                id='combo-box-demo'
                                type='time'
                                value={startTime}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '75%' }}
                                onChange={(event, item) => {
                                    setStartTime(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} className='pt-5'>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>Rules</h2>
                            <table className='mt-3' style={{ width: '80%' }}>
                                <colgroup>
                                    <col style={{ width: '100%' }} />
                                </colgroup>
                                <tbody>
                                    {rulesRows.map((row, index) => (
                                        <tr key={index}>
                                            <td className='border p-2'>
                                                <TextField sx={{ width: '100%' }} value={row} onChange={(e) => setRulesRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? e.target.value : prevRow)))} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='info' onClick={addRulesRow}>
                                Add Row
                            </Button>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-12 pb-12'>
                            <Button className='h-12' variant='contained' sx={{ width: '75%' }} color='info' onClick={addNewContest}>
                                Add Quiz
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AddContestV2
