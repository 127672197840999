import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'

export default function AddCoins() {
    const [username, setUsername] = useState('')
    const [coins, setCoins] = useState(0)
    const [reason, setReason] = useState('')
    const [info, setInfo] = useState('')
    const navigate = useNavigate()

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/users/coins`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    coins,
                    reason
                })
            })

            const data = await response.json()
            console.log('Data : ', data)
            setInfo(data.data.info)
            setUsername('')
            setCoins(0)
            setReason('')
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add Coins
                    </Typography>
                    <TextField label='Username' value={username} onChange={(event) => setUsername(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Coins' value={coins} onChange={(event) => setCoins(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Reason' value={reason} onChange={(event) => setReason(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    {info && <div className='mt-4 text-gray-700'>{info}</div>}
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveChanges}>
                            Add Coins
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
