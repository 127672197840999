import * as React from 'react'
import { useEffect } from 'react'
import { Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import ReactGA from 'react-ga'
import { formatDateToYYYYMMDD } from '../../Utils/Time'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddSeries = () => {
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [endDate, setEndDate] = useState(new Date().getTime() + 30 * 86400000)
    const [startDateInputValue, setStartDateInputValue] = useState(formatDateToYYYYMMDD(startDate))
    const [endDateInputValue, setEndDateInputValue] = useState(formatDateToYYYYMMDD(startDate + 30 * 86400000))
    const [startTime, setStartTime] = useState('09:00')
    const [quizName, setQuizName] = useState('')
    const [entryFees, setEntryFees] = useState(10)
    const [prizePoolMoney, setPrizePoolMoney] = useState(1000)
    const [quizDescription, setQuizDescription] = useState('')
    const [quizTagline, setQuizTagline] = useState('')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setQuizName('')
        setQuizDescription('')
        setEntryFees(10)
    }

    const addNewSeries = () => {
        if (quizName === '') {
            setOpenErrorAlert(true)
            return
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: quizName, description: quizDescription, startDate, endDate })
        }

        fetch(`${BASE_URL}/api/series`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                {' '}
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='text-4xl font-bold text-gray-500'> Add Series</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Series Name'
                                id='combo-box-demo'
                                value={quizName}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setQuizName(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Series Description'
                                id='combo-box-demo'
                                value={quizDescription}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setQuizDescription(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Start Date'
                                type='date'
                                value={startDateInputValue}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setStartDateInputValue(event.target.value)
                                    const myDate = event.target.value.split('-')
                                    const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                                    setStartDate(newDate)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='End Date'
                                type='date'
                                value={endDateInputValue}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setEndDateInputValue(event.target.value)
                                    const myDate = event.target.value.split('-')
                                    const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                                    setEndDate(newDate)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5 pb-12'>
                            <Button className='h-12' variant='contained' sx={{ width: '50%' }} color='info' onClick={addNewSeries}>
                                Add Series
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AddSeries
