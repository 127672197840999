import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'
import { fetchData } from '../../Api/useFetch'

export default function CancelContest() {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const contestId = searchParams.get('id')
    const teamName = searchParams.get('name')
    const [newTeamName, setNewTeamName] = useState(teamName)
    const [reason, setReason] = useState('')

    useEffect(() => {}, [])

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/contest-actions/cancel/${contestId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    reason
                })
            })
            if (response.ok) {
                navigate('/')
            } else {
                throw new Error('Failed to modify')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ marginY: 2 }}>
                        Cancel Contest
                    </Typography>
                    <>
                        <TextField disabled label='Name' value={newTeamName} onChange={(event) => setNewTeamName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                        <TextField label='Reason' value={reason} onChange={(event) => setReason(event.target.value)} fullWidth variant='outlined' margin='normal' />
                        <Button sx={{ mt: 2 }} variant='contained' color='error' onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                        <Button sx={{ mt: 2, ml: 2 }} variant='contained' color='info' onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </>
                </Box>
            </Box>
        </Box>
    )
}
