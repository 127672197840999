import React, { useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './Components/Home/Home'
import Question from './Components/Question/Question'
import AddContest from './Components/AddContest/AddContest'
import Header from './Components/Header/Header'
import EditContest from './Components/EditContest/EditContest'
import ContestLeaderboard from './Components/ContestLeaderboard/ContestLeaderboard'
import CompletedContests from './Components/CompletedContests/CompletedContests'
import ContestParticipants from './Components/ContestParticipants/ContestParticipants'
import ContestSubmissions from './Components/ContestSubmissions/ContestSubmissions'
import AppData from './Components/AppData/AppData'
import RewardRequests from './Components/RewardRequests/RewardRequests'
import CustomerTickets from './Components/CustomerTickets/CustomerTickets'
import isAuthenticated from './Auth/AuthenticateUser'
import Login from './Components/Login/Login'
import EditContestV2 from './Components/EditContestV2/EditContestV2'
import AddPlayer from './Components/AddPlayer/AddPlayer'
import AddContestV2 from './Components/AddContestV2/AddContestV2'
import LiveContests from './Components/LiveContests/LiveContests'
import EditPlayer from './Components/EditPlayer/EditPlayer'
import AddTeams from './Components/AddTeams/AddTeams'
import AddSeries from './Components/AddSeries/AddSeries'
import ViewPlayers from './Components/ViewPlayers/ViewPlayers'
import ViewSeries from './Components/ViewSeries/ViewSeries'
import ViewTeams from './Components/ViewTeams/ViewTeams'
import Users from './Components/Users/Users'
import AddCoins from './Components/AddCoins/AddCoins'
import SendMails from './Components/SendMails/SendMails'
import MailLogs from './Components/MailLogs/MailLogs'
import EditTeam from './Components/EditTeam/EditTeam'
import CancelContest from './Components/CancelContest/CancelContest'
import ContestLeaderboardLive from './Components/ContestLeaderboard/ContestLeaderboardLive'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/questions' element={<Question />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-contest' element={<AddContest />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-contest-v2' element={<AddContestV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/app-data' element={<AppData />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/reward-requests' element={<RewardRequests />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/customer-tickets' element={<CustomerTickets />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-contest/:id' element={<EditContest />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-contest-v2/:id' element={<EditContestV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/contest-leaderboard/:id' element={<ContestLeaderboard />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/contest-leaderboard-v2/:id' element={<ContestLeaderboardLive />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/contest-participants/:id' element={<ContestParticipants />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/completed-contests' element={<CompletedContests />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/live-contests' element={<LiveContests />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/contest-submissions/:id' element={<ContestSubmissions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/players' element={<AddPlayer />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-teams' element={<AddTeams />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-series' element={<AddSeries />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-player/:id' element={<EditPlayer />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-teams' element={<ViewTeams />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-series' element={<ViewSeries />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-players' element={<ViewPlayers />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/users' element={<Users />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-coins' element={<AddCoins />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/send-mails' element={<SendMails />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/mail-logs' element={<MailLogs />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-team' element={<EditTeam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/cancel-contest' element={<CancelContest />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
