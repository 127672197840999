import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography, MenuItem } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'

export default function SendMails() {
    const [userEmail, setUserEmail] = useState('')
    const [selectedTemplate, setSelectedTemplate] = useState('')
    const navigate = useNavigate()

    const templates = ['Template 1', 'Template 2', 'Template 3']

    const handleSendMail = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/mails/v1`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userEmail,
                    template: selectedTemplate
                })
            })

            const data = await response.json()
            console.log('Response :', data)
            setUserEmail('')
            setSelectedTemplate('')
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Send Mails
                    </Typography>
                    <TextField label='User Email' value={userEmail} onChange={(event) => setUserEmail(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField select label='Select Template' value={selectedTemplate} onChange={(event) => setSelectedTemplate(event.target.value)} fullWidth variant='outlined' margin='normal'>
                        {templates.map((template) => (
                            <MenuItem key={template} value={template}>
                                {template}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSendMail}>
                            Send Mail
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
