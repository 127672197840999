import * as React from 'react'
import { useEffect } from 'react'
import { Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import ReactGA from 'react-ga'
import { formatDateToYYYYMMDD } from '../../Utils/Time'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddContest = () => {
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [startDateInputValue, setStartDateInputValue] = useState(formatDateToYYYYMMDD(startDate))
    const [startTime, setStartTime] = useState('09:00')
    const [quizName, setQuizName] = useState('')
    const [entryFees, setEntryFees] = useState(10)
    const [prizePoolMoney, setPrizePoolMoney] = useState(1000)
    const [quizDescription, setQuizDescription] = useState('')
    const [quizTagline, setQuizTagline] = useState('')
    const [tableRows, setTableRows] = useState([
        { rank: 1, reward: '', coins: 0.5 * prizePoolMoney },
        { rank: 2, reward: '', coins: 0.3 * prizePoolMoney },
        { rank: 3, reward: '', coins: 0.1 * prizePoolMoney },
        { rank: 4, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 5, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 6, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 7, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 8, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 9, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 10, reward: '', coins: 0.09 * prizePoolMoney },
        { rank: 'Others', reward: '', coins: 0.09 * prizePoolMoney }
    ])

    useEffect(() => {
        const calculateTableRows = () => {
            const firstRankCoins = 0.5 * prizePoolMoney
            const secondRankCoins = 0.3 * prizePoolMoney
            const thirdRankCoins = 0.1 * prizePoolMoney
            const remainingPrizePool = prizePoolMoney - (firstRankCoins + secondRankCoins + thirdRankCoins)

            const updatedTableRows = [
                { rank: 1, reward: '', coins: firstRankCoins },
                { rank: 2, reward: '', coins: secondRankCoins },
                { rank: 3, reward: '', coins: thirdRankCoins },
                { rank: 4, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 5, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 6, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 7, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 8, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 9, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 10, reward: '', coins: remainingPrizePool * 0.09 },
                { rank: 'Others', reward: '', coins: remainingPrizePool * 0.01 }
            ]

            setTableRows(updatedTableRows)
        }

        calculateTableRows()
    }, [prizePoolMoney])

    const [rulesRows, setRulesRows] = useState([
        'All Questions are multiple choice.',
        'Every Question will have positive and negative points based on option selection.',
        'Points will be shown against each player while answering question.',
        'Once user clicks on join now button entry will be confirmed.',
        'Once Match Started user cant change their answers.',
        'Minimum 10 users need to join the contest to win iPhone',
        'In order to win iPhone, user must answer all questions correctly.',
        'If user is on table top, but answered only correct questions, that user cant qualify to win iPhone.',
        'If more 2 than users answered all questions correctly, one lucky winner will be selected using draw.',
        'Prizes will be distributed as per winnings table displayed under contest after match completed.',
        'This contest is completely free to join.',
        'If user wins iPhone, user will receive an email with the voucher details with in working days, no need to pay any amount.',
        'Only one entry per person allowed, if multiple entries found for any user, all his entries will be forfeit.',
        'Management decision is final, Management can cancel the contest without any notice at anytime.',
        'Each user can participate in one contest only one time'
    ])

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setQuizName('')
        setQuizDescription('')
        setEntryFees(10)
    }

    const getMergedDate = (date, time) => {
        const startDate = date
        const startTime = time
        const startDateObject = new Date(startDate)
        const [hours, minutes] = startTime.split(':')
        startDateObject.setHours(hours, minutes, 0, 0)
        const mergedDateTime = startDateObject.getTime()
        return mergedDateTime
    }

    const addNewContest = () => {
        if (quizName === '') {
            setOpenErrorAlert(true)
            return
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: quizName, description: quizDescription, tagline: quizTagline, startDate: getMergedDate(startDate, startTime), rewards: tableRows, rules: rulesRows, entryFees })
        }

        console.log('Request Options : ', requestOptions)

        fetch(`${BASE_URL}/api/admin/contest`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const addRow = () => {
        const lastSecondRow = { rank: tableRows[tableRows.length - 2].rank + 1, reward: '', coins: '' }
        const newRows = [...tableRows]
        newRows.splice(newRows.length - 1, 0, { ...lastSecondRow })
        setTableRows(newRows)
    }

    const addRulesRow = () => {
        const newRows = [...rulesRows, '']
        setRulesRows(newRows)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                {' '}
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='text-4xl font-bold text-gray-500 mt-5'> Add New Quiz</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Quiz Name'
                                id='combo-box-demo'
                                value={quizName}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setQuizName(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Quiz Description'
                                id='combo-box-demo'
                                value={quizDescription}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setQuizDescription(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Tagline'
                                id='combo-box-demo'
                                value={quizTagline}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setQuizTagline(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Entry Fees'
                                type='number'
                                value={entryFees}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setEntryFees(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Prize Pool'
                                type='number'
                                value={prizePoolMoney}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setPrizePoolMoney(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Start Date'
                                type='date'
                                value={startDateInputValue}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setStartDateInputValue(event.target.value)
                                    const myDate = event.target.value.split('-')
                                    const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                                    setStartDate(newDate)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                label='Start Time'
                                id='combo-box-demo'
                                type='time'
                                value={startTime}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '50%' }}
                                onChange={(event, item) => {
                                    setStartTime(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} className='pt-5'>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>Rewards</h2>
                            <table className='border border-collapse border-gray-800 mt-3 text-center' style={{ width: '50%' }}>
                                <colgroup>
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '70%' }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className='border p-2'>Rank</th>
                                        <th className='border p-2'>Coins</th>
                                        <th className='border p-2'>Reward</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.map((row, index) => (
                                        <tr key={index}>
                                            <td className='border p-2'>
                                                <TextField sx={{ width: '100%' }} value={row.rank} onChange={(e) => setTableRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, rank: e.target.value } : prevRow)))} />
                                            </td>

                                            <td className='border p-2'>
                                                <TextField sx={{ width: '100%' }} type='number' value={row.coins} onChange={(e) => setTableRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, coins: e.target.value } : prevRow)))} />
                                            </td>
                                            <td className='border p-2'>
                                                <TextField sx={{ width: '100%' }} value={row.reward} onChange={(e) => setTableRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, reward: e.target.value } : prevRow)))} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='info' onClick={addRow}>
                                Add Row
                            </Button>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} className='pt-5'>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>Rules</h2>
                            <table className='mt-3' style={{ width: '50%' }}>
                                <colgroup>
                                    <col style={{ width: '100%' }} />
                                </colgroup>
                                <tbody>
                                    {rulesRows.map((row, index) => (
                                        <tr key={index}>
                                            <td className='border p-2'>
                                                <TextField sx={{ width: '100%' }} value={row} onChange={(e) => setRulesRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? e.target.value : prevRow)))} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='info' onClick={addRulesRow}>
                                Add Row
                            </Button>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5 pb-12'>
                            <Button className='h-12' variant='contained' sx={{ width: '50%' }} color='info' onClick={addNewContest}>
                                Add Quiz
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AddContest
