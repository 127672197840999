import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/system'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { Autocomplete, Button, Grid, TextField, capitalize } from '@mui/material'
import { styled } from '@mui/material/styles'
import { fetchData } from '../../Api/useFetch'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { showTimeInAgoFormat } from '../../Utils/Time'
import Backdrop from '@mui/material/Backdrop'
import MuiAlert from '@mui/material/Alert'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { defaultModalStyle } from '../../Styles/Modal'
import { capitalizeFirstCharacter } from '../../Utils/String'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function RewardRequests() {
    const location = useLocation()
    const [apiCalled, setApiCalled] = useState(false)
    const [submissionsList, setSubmissionsList] = useState([])

    // MODAL
    const [open, setOpen] = React.useState(false)
    const [openEditModal, setOpenEditModal] = React.useState(false)
    //EditModal
    const handleOpen = (reward) => {
        setQuestionData(reward)
        console.log('New data : ', reward)
        setOpen(true)
    }
    const handleClose = () => setOpen(false)

    const [questionData, setQuestionData] = useState({
        email: '',
        name: '',
        note: '',
        coins: 0,
        time: 0,
        status: 'pending'
    })

    const updateQuestionData = (property, value, index = null) => {
        setQuestionData((prevData) => {
            return {
                ...prevData,
                [property]: value
            }
        })
    }

    const getSubmissionsList = async (contestId) => {
        const data = await fetchData(`api/user/rewards/redeem`)
        console.log('Data : ', data)
        if (data) {
            setApiCalled(true)
            setSubmissionsList(data.rewards)
        }
    }

    const saveRewardState = () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ status: questionData.status, note: questionData.note })
        }

        fetch(`${BASE_URL}/api/user/rewards/redeem/${questionData._id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    handleClose()
                    getSubmissionsList()
                } else {
                }
            })
    }

    useEffect(() => {
        const path = location.pathname
        if (!apiCalled) {
            getSubmissionsList()
        }
    }, [apiCalled, location.pathname])

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-96'>
                        <Box sx={{ flexGrow: 2 }}>
                            <h1 className='my-5 text-2xl font-bold text-center text-gray-500'>Redeem Requests</h1>
                            <Grid align='center' justify='center' sx={{ margin: 1 }}>
                                <>
                                    <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>S.No</StyledTableCell>
                                                <StyledTableCell>Email</StyledTableCell>
                                                <StyledTableCell align='left'>Name</StyledTableCell>
                                                <StyledTableCell align='left'>Coins</StyledTableCell>
                                                <StyledTableCell align='left'>Requested At</StyledTableCell>
                                                <StyledTableCell align='left'>Redeem</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {submissionsList.length > 0 &&
                                                submissionsList.map((data, index) => {
                                                    return (
                                                        <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.User ? data.User.email : 'Not found'}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.name}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.coins}</StyledTableCell>
                                                            <StyledTableCell align='left'>{showTimeInAgoFormat(data.time)}</StyledTableCell>
                                                            <StyledTableCell align='left' onClick={() => handleOpen(data)}>
                                                                <span className='text-blue-500 underline cursor-pointer'>Redeem</span>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </>
                            </Grid>
                        </Box>
                        <Modal
                            aria-labelledby='transition-modal-title'
                            aria-describedby='transition-modal-description'
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500
                                }
                            }}>
                            <Fade in={open}>
                                <Box sx={defaultModalStyle}>
                                    <div className='flex flex-col gap-6 justify-center'>
                                        <h1 className='text-center text-2xl font-bold text-gray-700'>Redeem Reward</h1>
                                        <TextField
                                            id='outlined-controlled'
                                            disabled={true}
                                            label='Reward Name'
                                            sx={{ width: '100%' }}
                                            value={questionData.name}
                                            onChange={(event) => {
                                                updateQuestionData('name', event.target.value)
                                            }}
                                        />
                                        <TextField
                                            id='outlined-controlled'
                                            disabled={true}
                                            label='Reward Coins'
                                            sx={{ width: '100%' }}
                                            value={questionData.coins}
                                            onChange={(event) => {
                                                updateQuestionData('coins', event.target.value)
                                            }}
                                        />
                                        <TextField
                                            id='outlined-controlled'
                                            disabled={true}
                                            label='Redeemed At'
                                            sx={{ width: '100%' }}
                                            value={showTimeInAgoFormat(questionData.time)}
                                            onChange={(event) => {
                                                updateQuestionData('coins', event.target.value)
                                            }}
                                        />
                                        <TextField
                                            id='outlined-controlled'
                                            label='Note'
                                            sx={{ width: '100%' }}
                                            value={questionData.note}
                                            onChange={(event) => {
                                                updateQuestionData('note', event.target.value)
                                            }}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            id='combo-box-demo'
                                            value={questionData.status}
                                            options={['Pending', 'Complete']}
                                            sx={{ width: '100%' }}
                                            onChange={(event, item) => {
                                                console.log('Event : ', event)
                                                updateQuestionData('status', item)
                                            }}
                                            renderInput={(params) => <TextField {...params} label='Reward Status' />}
                                        />
                                        <Button variant='contained' color='success' sx={{ width: '100%', cursor: 'pointer' }} onClick={saveRewardState}>
                                            Save
                                        </Button>
                                    </div>
                                </Box>
                            </Fade>
                        </Modal>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default RewardRequests
