import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { fetchData } from '../../Api/useFetch'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { showTimeInAgoFormat } from '../../Utils/Time'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { defaultModalStyle } from '../../Styles/Modal'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'
import { Delete, Edit } from '@mui/icons-material'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function ViewPlayers() {
    const location = useLocation()
    const [apiCalled, setApiCalled] = useState(false)
    const navigate = useNavigate()
    const [submissionsList, setSubmissionsList] = useState([])
    const [filteredSubmissionsList, setFilteredSubmissionsList] = useState([]) // Add state for filtered list

    // Filters state
    const [filterTeam, setFilterTeam] = useState(null)
    const [filterSeries, setFilterSeries] = useState(null)
    const [filterRole, setFilterRole] = useState(null)

    useEffect(() => {
        applyFilters()
    }, [submissionsList, filterTeam, filterSeries, filterRole])

    const applyFilters = () => {
        let filteredList = [...submissionsList]

        if (filterTeam) {
            filteredList = filteredList.filter((item) => item.team === filterTeam)
        }
        if (filterSeries) {
            filteredList = filteredList.filter((item) => item.series === filterSeries)
        }
        if (filterRole) {
            filteredList = filteredList.filter((item) => item.role === filterRole)
        }

        setFilteredSubmissionsList(filteredList)
    }

    const getSubmissionsList = async (contestId) => {
        const data = await fetchData(`api/admin/teams/players`)
        console.log('Data : ', data)
        if (data) {
            setApiCalled(true)
            setSubmissionsList(data.players)
            setFilteredSubmissionsList(data.players)
        }
    }

    const handleDeleteClick = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/admin/teams/players/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    getSubmissionsList()
                } else {
                }
            })
    }

    const handleEditClick = (id) => {
        navigate('/edit-player/' + id)
    }

    useEffect(() => {
        const path = location.pathname
        if (!apiCalled) {
            getSubmissionsList()
        }
    }, [apiCalled, location.pathname])

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-96'>
                        <Box sx={{ flexGrow: 2 }}>
                            <h1 className='my-5 text-2xl font-bold text-center text-gray-500'>All Players</h1>
                            <Grid align='center' justify='center' sx={{ margin: 1 }}>
                                <>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete options={[...new Set(submissionsList.map((item) => item.team))]} value={filterTeam} onChange={(event, newValue) => setFilterTeam(newValue)} renderInput={(params) => <TextField {...params} label='Team' />} />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete options={[...new Set(submissionsList.map((item) => item.series))]} value={filterSeries} onChange={(event, newValue) => setFilterSeries(newValue)} renderInput={(params) => <TextField {...params} label='Series' />} />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete options={[...new Set(submissionsList.map((item) => item.role))]} value={filterRole} onChange={(event, newValue) => setFilterRole(newValue)} renderInput={(params) => <TextField {...params} label='Role' />} />
                                        </Grid>
                                    </Grid>
                                    <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Player</StyledTableCell>
                                                <StyledTableCell>Team</StyledTableCell>
                                                <StyledTableCell>Series</StyledTableCell>
                                                <StyledTableCell>Role</StyledTableCell>
                                                <StyledTableCell>Correct Score</StyledTableCell>
                                                <StyledTableCell>Incorrect Score</StyledTableCell>
                                                <StyledTableCell>Batting Correct Score</StyledTableCell>
                                                <StyledTableCell>Batting Incorect Score Score</StyledTableCell>
                                                <StyledTableCell>Bowling Correct Score</StyledTableCell>
                                                <StyledTableCell>Bowling Incorrect Score</StyledTableCell>
                                                <StyledTableCell>Updated At</StyledTableCell>
                                                <StyledTableCell>Edit</StyledTableCell>
                                                <StyledTableCell>Delete</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredSubmissionsList.length > 0 &&
                                                filteredSubmissionsList.map((data, index) => {
                                                    return (
                                                        <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                                            <StyledTableCell align='left'>{data.player}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.team}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.series}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.role}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.correctScore}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.incorrectScore}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.batting && data.batting.correctScore}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.batting && data.batting.incorrectScore}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.bowling && data.bowling.correctScore}</StyledTableCell>
                                                            <StyledTableCell align='left'>{data.bowling && data.bowling.incorrectScore}</StyledTableCell>
                                                            <StyledTableCell align='left'>{showTimeInAgoFormat(data.updatedAt)}</StyledTableCell>
                                                            <StyledTableCell align='left'>
                                                                <Edit onClick={() => handleEditClick(data._id)} sx={{ color: '#4aac2d', cursor: 'pointer' }} />
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left'>
                                                                <Delete onClick={() => handleDeleteClick(data._id)} sx={{ color: '#f34848', cursor: 'pointer' }} />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </>
                            </Grid>
                        </Box>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default ViewPlayers
