import * as React from 'react'
import { useEffect } from 'react'
import { Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import ReactGA from 'react-ga'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AppData = () => {
    const [rulesRows, setRulesRows] = useState(['Each user can participate in one contest only one time'])
    const [sliderImages, setSliderImages] = useState([''])
    const [faqRows, setFaqRows] = useState([{ question: '', answer: '' }])
    const [supportEmails, setSupportEmails] = useState(['support@example.com'])

    const [rewardRows, setRewardRows] = useState([{ name: '', coins: '', image: '' }])

    const addRewardRow = () => {
        const newRows = [...rewardRows, { name: '', coins: '' }]
        setRewardRows(newRows)
    }

    const updateRewardsData = () => {
        updateSettingsData('rewards', rewardRows)
    }

    const addNewSliderImage = () => {
        const newSliderList = [...sliderImages, '']
        setSliderImages(newSliderList)
    }

    const addSupportEmail = () => {
        const newEmails = [...supportEmails, '']
        setSupportEmails(newEmails)
    }

    const addFaqRow = () => {
        const newRows = [...faqRows, { question: '', answer: '' }]
        setFaqRows(newRows)
    }

    const updateSettings = (settingList) => {
        try {
            settingList.forEach((setting) => {
                if (setting.type === 'how_to_play') {
                    setRulesRows(setting.list)
                } else if (setting.type === 'faqs') {
                    setFaqRows(setting.list)
                } else if (setting.type === 'support_emails') {
                    setSupportEmails(setting.list)
                } else if (setting.type === 'rewards') {
                    setRewardRows(setting.list)
                } else if (setting.type === 'slider_images') {
                    setSliderImages(setting.list)
                }
            })
        } catch {}
    }

    const getSettingsData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/settings/data`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Settings Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    updateSettings(data.data.settings)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const updateSettingsData = (type, list) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: type, list: list })
        }

        console.log('Request Options : ', requestOptions)

        fetch(`${BASE_URL}/api/settings/data`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    // setOpenSuccessAlert(true)
                } else {
                    // setOpenErrorAlert(true)
                }
            })
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const addRulesRow = () => {
        const newRows = [...rulesRows, '']
        setRulesRows(newRows)
    }

    useEffect(() => {
        getSettingsData()
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ flexGrow: 2, marginBottom: '300px' }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={12} md={12} sm={12} className=''>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>Rewards</h2>

                            {rewardRows.map((row, index) => (
                                <div key={index} className='my-2'>
                                    <TextField sx={{ width: '40%' }} label={`Reward Name ${index + 1}`} value={row.name} onChange={(e) => setRewardRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, name: e.target.value } : prevRow)))} />
                                    <TextField sx={{ width: '20%', marginLeft: '10px' }} label={`Coins ${index + 1}`} value={row.coins} onChange={(e) => setRewardRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, coins: e.target.value } : prevRow)))} />
                                    <TextField sx={{ width: '20%', marginLeft: '10px' }} label={`Image ${index + 1}`} value={row.image} onChange={(e) => setRewardRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, image: e.target.value } : prevRow)))} />
                                </div>
                            ))}
                            <Button sx={{ marginTop: '10px', marginRight: '10px' }} variant='contained' color='info' onClick={addRewardRow}>
                                Add New Reward
                            </Button>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='success' onClick={() => updateSettingsData('rewards', rewardRows)}>
                                Update Rewards
                            </Button>
                        </Grid>
                    </Grid>
                    <hr className='my-8 mx-8 border' />
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={12} md={12} sm={12} className=''>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>Slider Images </h2>

                            {sliderImages.map((row, index) => (
                                <div className='my-2'>
                                    <TextField sx={{ width: '80%' }} value={row} onChange={(e) => setSliderImages((prevRows) => prevRows.map((prevRow, i) => (i === index ? e.target.value : prevRow)))} />
                                </div>
                            ))}
                            <Button sx={{ marginTop: '10px', marginRight: '10px' }} variant='contained' color='info' onClick={addNewSliderImage}>
                                Add New Slider Image
                            </Button>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='success' onClick={() => updateSettingsData('slider_images', sliderImages)}>
                                Update Slider Images
                            </Button>
                        </Grid>
                    </Grid>
                    <hr className='my-8 mx-8 border' />
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={12} md={12} sm={12} className=''>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>How to Play </h2>

                            {rulesRows.map((row, index) => (
                                <div className='my-2'>
                                    <TextField sx={{ width: '80%' }} value={row} onChange={(e) => setRulesRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? e.target.value : prevRow)))} />
                                </div>
                            ))}
                            <Button sx={{ marginTop: '10px', marginRight: '10px' }} variant='contained' color='info' onClick={addRulesRow}>
                                Add New Rule
                            </Button>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='success' onClick={() => updateSettingsData('how_to_play', rulesRows)}>
                                Update How to Play
                            </Button>
                        </Grid>
                    </Grid>
                    <hr className='my-8 mx-8 border' />
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={12} md={12} sm={12} className=''>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>FAQ</h2>

                            {faqRows.map((row, index) => (
                                <div key={index} className='my-2'>
                                    <TextField sx={{ width: '40%' }} label={`Question ${index + 1}`} value={row.question} onChange={(e) => setFaqRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, question: e.target.value } : prevRow)))} />
                                    <TextField sx={{ width: '40%', marginLeft: '10px' }} label={`Answer ${index + 1}`} value={row.answer} onChange={(e) => setFaqRows((prevRows) => prevRows.map((prevRow, i) => (i === index ? { ...prevRow, answer: e.target.value } : prevRow)))} />
                                </div>
                            ))}
                            <Button sx={{ marginTop: '10px', marginRight: '10px' }} variant='contained' color='info' onClick={addFaqRow}>
                                Add New FAQ
                            </Button>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='success' onClick={() => updateSettingsData('faqs', faqRows)}>
                                Update FAQ
                            </Button>
                        </Grid>
                    </Grid>
                    <hr className='my-8 mx-8 border' />
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={12} md={12} sm={12} className=''>
                            <h2 className='text-2xl font-bold text-gray-500 mt-5'>Support Emails</h2>

                            {supportEmails.map((email, index) => (
                                <div key={index} className='my-2'>
                                    <TextField sx={{ width: '80%' }} label={`Support Email ${index + 1}`} value={email} onChange={(e) => setSupportEmails((prevEmails) => prevEmails.map((prevEmail, i) => (i === index ? e.target.value : prevEmail)))} />
                                </div>
                            ))}
                            <Button sx={{ marginTop: '10px', marginRight: '10px' }} variant='contained' color='info' onClick={addSupportEmail}>
                                Add Support Email
                            </Button>
                            <Button sx={{ marginTop: '10px' }} variant='contained' color='success' onClick={() => updateSettingsData('support_emails', supportEmails)}>
                                Update Support Emails
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AppData
