import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { AutoGraph, CardGiftcard, Cast, CurrencyBitcoin, CurrencyRupee, DashboardCustomizeSharp, DataArray, DesktopWindowsOutlined, DesktopWindowsTwoTone, Email, Groups, LibraryAddCheck, LiveTv, Logout, MarkEmailRead, Movie, Person, SportsCricketTwoTone } from '@mui/icons-material'

function DrawerNav() {
    const navigate = useNavigate()
    const location = useLocation()
    const handleRoute = (url) => {
        navigate('/' + url)
    }

    const isMobile = useMediaQuery('(max-width:600px)')

    const contestListItems = [
        {
            value: 'Upcoming Contests',
            route: '',
            icon: <AutoGraph />
        },
        {
            value: 'Live Contests',
            route: 'live-contests',
            icon: <LiveTv />
        },
        {
            value: 'Completed Contests',
            route: 'completed-contests',
            icon: <LibraryAddCheck />
        }
    ]

    const contestListItemsTwo = [
        {
            value: 'Add Contest V1',
            route: 'add-contest',
            icon: <Cast />
        },
        {
            value: 'Add Contest V2',
            route: 'add-contest-v2',
            icon: <Movie />
        }
    ]

    const mainListItems = [
        {
            value: 'Add Series',
            route: 'add-series',
            icon: <DesktopWindowsTwoTone />
        },
        {
            value: 'View Series',
            route: 'view-series',
            icon: <DesktopWindowsOutlined />
        },
        {
            value: 'Add Teams',
            route: 'add-teams',
            icon: <Groups />
        },
        {
            value: 'View Teams',
            route: 'view-teams',
            icon: <Groups />
        },
        {
            value: 'Add Player',
            route: 'players',
            icon: <Person />
        },
        {
            value: 'View Players',
            route: 'view-players',
            icon: <Person />
        }
    ]

    const appDataItems = [
        {
            value: 'Add Coins',
            route: 'add-coins',
            icon: <CurrencyBitcoin />
        },
        {
            value: 'Send Mails',
            route: 'send-mails',
            icon: <Email />
        },
        {
            value: 'Mail Logs',
            route: 'mail-logs',
            icon: <MarkEmailRead />
        },
        {
            value: 'App Data',
            route: 'app-data',
            icon: <DataArray />
        },
        {
            value: 'Tickets',
            route: 'customer-tickets',
            icon: <DashboardCustomizeSharp />
        },
        {
            value: 'Rewards',
            route: 'reward-requests',
            icon: <CardGiftcard />
        },
        {
            value: 'Users',
            route: 'users',
            icon: <Person/>
        }
    ]

    const drawerWidth = 300

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {contestListItems.map((item) => (
                            <ListItem
                                key={item.route}
                                disablePadding
                                sx={{
                                    backgroundColor: location.pathname === `/${item.route}` ? 'rgba(63, 59, 59, 0.144)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'rgba(63, 59, 59, 0.144)'
                                    }
                                }}>
                                <ListItemButton onClick={() => handleRoute(item.route)}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {contestListItemsTwo.map((item) => (
                            <ListItem
                                key={item.route}
                                disablePadding
                                sx={{
                                    backgroundColor: location.pathname === `/${item.route}` ? 'rgba(63, 59, 59, 0.144)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'rgba(63, 59, 59, 0.144)'
                                    }
                                }}>
                                <ListItemButton onClick={() => handleRoute(item.route)}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {mainListItems.map((item) => (
                            <ListItem
                                key={item.route}
                                disablePadding
                                sx={{
                                    backgroundColor: location.pathname === `/${item.route}` ? 'rgba(63, 59, 59, 0.144)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'rgba(63, 59, 59, 0.144)'
                                    }
                                }}>
                                <ListItemButton onClick={() => handleRoute(item.route)}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {appDataItems.map((item) => (
                            <ListItem
                                key={item.route}
                                disablePadding
                                sx={{
                                    backgroundColor: location.pathname === `/${item.route}` ? 'rgba(63, 59, 59, 0.144)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'rgba(63, 59, 59, 0.144)'
                                    }
                                }}>
                                <ListItemButton onClick={() => handleRoute(item.route)}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        <ListItem
                            key={'logout'}
                            disablePadding
                            sx={{
                                backgroundColor: location.pathname === `/logout` ? 'rgba(63, 59, 59, 0.144)' : 'transparent',
                                '&:hover': {
                                    backgroundColor: 'rgba(63, 59, 59, 0.144)'
                                }
                            }}>
                            <ListItemButton
                                onClick={() => {
                                    localStorage.clear()
                                    handleRoute('login')
                                }}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText primary='Logout' />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    )
}

export default DrawerNav
