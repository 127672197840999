import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import { BASE_URL } from '../../Api/Constants'
import { Box } from '@mui/system'
import DrawerNav from '../DrawerNav/DrawerNav'
import { useLocation, useNavigate } from 'react-router-dom'
import { PLAYER_ROLES } from '../../Data/Teams'
import { Stack, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { fetchData } from '../../Api/useFetch'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const EditPlayer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [playerId, setPlayerId] = useState('')
    const [playerData, setPlayerData] = useState({
        player: '',
        team: '',
        series: '',
        correctScore: 0,
        incorrectScore: 0,
        battingCorrectScore: 0,
        battingIncorrectScore: 0,
        bowlingCorrectScore: 0,
        bowlingIncorrectScore: 0,
        role: 'Batter',
        imageURL: ''
    })

    useEffect(() => {
        const getPlayerDetail = async (id) => {
            try {
                const response = await fetch(`${BASE_URL}/api/teams/player/${id}`)
                if (response.ok) {
                    const data = await response.json()
                    const player = data.data.player
                    setPlayerData({
                        player: player.player,
                        team: player.team,
                        correctScore: player.correctScore,
                        incorrectScore: player.incorrectScore,
                        series: player.series,
                        battingCorrectScore: player.batting ? player.batting.correctScore : 0,
                        battingIncorrectScore: player.batting ? player.batting.incorrectScore : 0,
                        bowlingCorrectScore: player.bowling ? player.bowling.correctScore : 0,
                        bowlingIncorrectScore: player.bowling ? player.bowling.incorrectScore : 0,
                        role: player.role,
                        imageURL: player.imageURL
                    })
                } else {
                    throw new Error('Failed to fetch player data')
                }
            } catch (error) {
                console.error('Error fetching player data:', error)
                // Handle error
            }
        }

        const path = location.pathname
        const playerId = path.substring(13)
        console.log('Player ID : ', playerId)
        setPlayerId(playerId)
        getPlayerDetail(playerId)
    }, [location.pathname])

    // Handle changes in input fields
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setPlayerData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    // Handle changes in Autocomplete fields
    const handleAutocompleteChange = (field, value) => {
        setPlayerData((prevData) => ({
            ...prevData,
            [field]: value
        }))
    }

    // Handle saving player data
    const savePlayerData = async () => {
        try {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(playerData)
            }

            const response = await fetch(`${BASE_URL}/api/teams/player/${playerId}`, requestOptions)
            if (response.ok) {
                // Player data saved successfully
                console.log('Player data saved successfully')
                setSuccessMessage('Player data saved successfully')
                setOpenSuccessAlert(true)
                navigate(-1)
            } else {
                throw new Error('Failed to save player data')
            }
        } catch (error) {
            console.error('Error saving player data:', error)
            // Handle error
        }
    }

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')
    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const [apiCalled, setApiCalled] = useState(false)
    const [teamsList, setTeamsList] = useState([])
    const [seriesList, setSeriesList] = useState([])

    const handleTeamsList = (list) => {
        let teams_list = []
        list.forEach((data) => {
            teams_list.push(data.name)
        })
        setTeamsList(teams_list)
    }

    const handleSeriesList = (list) => {
        let series_list = []
        list.forEach((data) => {
            series_list.push(data.name)
        })
        setSeriesList(series_list)
    }

    const getTeamsList = async (contestId) => {
        const data = await fetchData(`api/teams`)
        const seriesData = await fetchData(`api/series`)
        console.log('Data : ', data)
        if (data) {
            setApiCalled(true)
            handleTeamsList(data.teamsList)
        }
        if (seriesData) {
            handleSeriesList(seriesData.seriesList)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTeamsList()
        }
    })

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <div className='text-center text-2xl font-bold mb-8'>Edit Player</div>
                    <div className='mt-4 w-4/5 flex flex-wrap m-auto gap-4 justify-center'>
                        <TextField sx={{ width: '49%' }} id='player-name' name='player' label='Player Name' value={playerData.player} onChange={handleInputChange} />
                        <TextField sx={{ width: '49%' }} id='image-url' name='imageURL' label='Image URL' value={playerData.imageURL} onChange={handleInputChange} />
                        <TextField sx={{ width: '49%' }} id='correct-score' name='correctScore' label='Default Correct Score' type='number' value={playerData.correctScore} onChange={handleInputChange} />
                        <Autocomplete sx={{ width: '49%' }} id='team-name' options={teamsList} value={playerData.team} onChange={(event, value) => handleAutocompleteChange('team', value)} renderInput={(params) => <TextField {...params} label='Team Name' />} />
                        <Autocomplete sx={{ width: '49%' }} id='series-name' options={seriesList} value={playerData.series} onChange={(event, value) => handleAutocompleteChange('series', value)} renderInput={(params) => <TextField {...params} label='Series Name' />} />
                        <Autocomplete sx={{ width: '49%' }} id='player-role' options={PLAYER_ROLES} value={playerData.role} onChange={(event, value) => handleAutocompleteChange('role', value)} renderInput={(params) => <TextField {...params} label='Player Role' />} />
                        <TextField sx={{ width: '49%' }} id='incorrect-score' name='incorrectScore' label='Default Incorrect Score' type='number' value={playerData.incorrectScore} onChange={handleInputChange} />
                        <TextField sx={{ width: '49%' }} id='batting-correct-score' name='battingCorrectScore' label='Batting Correct Score' type='number' value={playerData.battingCorrectScore} onChange={handleInputChange} />
                        <TextField sx={{ width: '49%' }} id='batting-incorrect-score' name='battingIncorrectScore' label='Batting Incorrect Score' type='number' value={playerData.battingIncorrectScore} onChange={handleInputChange} />
                        <TextField sx={{ width: '49%' }} id='bowling-correct-score' name='bowlingCorrectScore' label='Bowling Correct Score' type='number' value={playerData.bowlingCorrectScore} onChange={handleInputChange} />
                        <TextField sx={{ width: '49%' }} id='bowling-incorrect-score' name='bowlingIncorrectScore' label='Bowling Incorrect Score' type='number' value={playerData.bowlingIncorrectScore} onChange={handleInputChange} />
                        <Button variant='contained' color='success' onClick={savePlayerData}>
                            Save Player Data
                        </Button>
                    </div>
                </div>{' '}
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>
    )
}

export default EditPlayer
