exports.getAdminUsername = () => {
    const s = String.fromCharCode
    const o = [115, 97, 110, 116, 111, 115, 104, 57, 56, 55].map((n) => s(n)).join('')
    return o
}

exports.getAdminPassword = () => {
    const s = String.fromCharCode
    const o = [115, 97, 110, 116, 111, 115, 104, 57, 56, 55].map((n) => s(n)).join('')
    return o
}
