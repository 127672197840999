/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Card, CardContent, Snackbar, Stack, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import DrawerNav from '../DrawerNav/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function CompletedContets() {
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        navigate(route)
    }
    const [completedContests, setCompletedContests] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    const getContestsList = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/user/completedContests`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setCompletedContests(data.data.contests)
                    setApiCalled(true)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const handleDeleteContest = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/admin/contest/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    getContestsList()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const handleEditClick = (id) => {
        navigate('/edit-contest-v2/' + id)
    }

    const openLeaderboardScreen = (id) => {
        navigate('/contest-leaderboard/' + id)
    }

    const openSubmissionsScreen = (id) => {
        navigate('/contest-submissions/' + id)
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    useEffect(() => {
        if (!apiCalled) {
            getContestsList()
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='p-4 grid grid-cols-1 md:grid-cols-1 gap-4'>
                        <div>
                            {completedContests.length > 0 && <h1 className='text-xl font-bold text-gray-700'>Completed Contests</h1>}
                            {completedContests.length > 0 &&
                                completedContests.map((contest, index) => {
                                    if (!contest.awarded) {
                                        return (
                                            <Card key={index} className='my-2' sx={{ backgroundColor: 'whitesmoke' }}>
                                                <CardContent>
                                                    <Typography variant='h6' component='div'>
                                                        {contest.name}
                                                        {' • '}
                                                        {contest.description}
                                                        {' • '}
                                                        <span class='bg-yellow-300 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-yellow-300'>Reward Distribution Pending</span>
                                                    </Typography>
                                                    <Typography variant='body2' color='text.secondary'>
                                                        Start Date: {showTimeInAgoFormat(contest.startDate)}
                                                        {' • '}
                                                        Participants: {contest.participants ? contest.participants : 0}
                                                    </Typography>
                                                    <div>
                                                        <Button variant='contained' color='success' onClick={() => handleEditClick(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                            Edit
                                                        </Button>
                                                        <Button variant='contained' color='success' onClick={() => openSubmissionsScreen(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                            Submissions
                                                        </Button>
                                                        <Button variant='contained' color='info' onClick={() => openLeaderboardScreen(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                            Leaderboard
                                                        </Button>
                                                        <Button variant='contained' color='error' onClick={() => handleDeleteContest(contest._id)} sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                    }
                                })}
                            {completedContests.length > 0 &&
                                completedContests.map((contest, index) => {
                                    if (contest.awarded) {
                                        return (
                                            <Card key={index} className='my-2' sx={{ backgroundColor: 'whitesmoke' }}>
                                                <CardContent>
                                                    <Typography variant='h6' component='div'>
                                                        {contest.name}
                                                        {' • '}
                                                        {contest.description}
                                                       </Typography>
                                                    <Typography variant='body2' color='text.secondary'>
                                                        Start Date: {showTimeInAgoFormat(contest.startDate)}
                                                        {' • '}
                                                        Participants: {contest.participants ? contest.participants : 0}
                                                    </Typography>
                                                    <div>
                                                        <Button variant='contained' color='success' onClick={() => handleEditClick(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                            Edit
                                                        </Button>
                                                        <Button variant='contained' color='success' onClick={() => openSubmissionsScreen(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                            Submissions
                                                        </Button>
                                                        <Button variant='contained' color='info' onClick={() => openLeaderboardScreen(contest._id)} sx={{ marginTop: '10px', marginRight: '10px', cursor: 'pointer' }}>
                                                            Leaderboard
                                                        </Button>
                                                        <Button variant='contained' color='error' onClick={() => handleDeleteContest(contest._id)} sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                    }
                                })}
                        </div>

                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                                <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                    Success!!!
                                </Alert>
                            </Snackbar>
                        </Stack>

                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                                <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                    Error!!!
                                </Alert>
                            </Snackbar>
                        </Stack>
                    </div>
                    {completedContests.length === 0 && <h1 className='text-2xl mt-10 text-center text-gray-700'>No contest available</h1>}
                </Box>
            </Box>
        </>
    )
}

export default CompletedContets
