import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'
import { fetchData } from '../../Api/useFetch'

export default function EditTeam() {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const teamId = searchParams.get('id')
    const teamName = searchParams.get('name')
    const teamShortName = searchParams.get('shortName')
    const teamSeries = searchParams.get('series')
    const teamImageURL = searchParams.get('imageURL')
    const [newTeamName, setNewTeamName] = useState(teamName)
    const [newteamShortName, setNewTeamShortName] = useState(teamShortName)
    const [newTeamSeries, setNewTeamSeries] = useState(teamSeries)
    const [newTeamImageURL, setNewTeamImageURL] = useState(teamImageURL)
    // const [newTopic, setNewTopic] = useState(topicName)

    useEffect(() => {}, [])

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/teams/${teamId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newTeamName,
                    shortName: newteamShortName,
                    series: newTeamSeries,
                    imageURL: newTeamImageURL
                })
            })
            if (response.ok) {
                console.log('Subject modified successfully')
                navigate('/view-teams')
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [seriesList, setSeriesList] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    const handleSeriesList = (list) => {
        let teamArr = []
        list.forEach((data) => {
            teamArr.push(data.name)
        })
        setSeriesList(teamArr)
    }

    const getSeriesList = async (contestId) => {
        const data = await fetchData(`api/series`)
        console.log('Data : ', data)
        if (data) {
            setApiCalled(true)
            handleSeriesList(data.seriesList)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getSeriesList()
        }
    })

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ marginY: 2 }}>
                        Edit Team
                    </Typography>

                    <>
                        <TextField label='Name' value={newTeamName} onChange={(event) => setNewTeamName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                        <FormControl fullWidth variant='outlined' margin='normal'>
                            <InputLabel id='select-topic-label'>Choose Series</InputLabel>
                            <Select labelId='select-topic-label' id='select-topic' value={newTeamSeries} onChange={(event) => setNewTeamSeries(event.target.value)} label='Choose Topic'>
                                {seriesList.map((topic, index) => (
                                    <MenuItem key={index} value={topic}>
                                        {topic}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField label='Short Name' value={newteamShortName} onChange={(event) => setNewTeamShortName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                        <TextField label='Image URL' value={newTeamImageURL} onChange={(event) => setNewTeamImageURL(event.target.value)} fullWidth variant='outlined' margin='normal' />
                        <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </>
                </Box>
            </Box>
        </Box>
    )
}
