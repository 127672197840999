export const defaultModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'white',
    border: '1px solid #b3b2b2',
    boxShadow: 24,
    p: 4,
    overflow: 'auto' 
};