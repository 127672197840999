import * as React from 'react'
import { useEffect } from 'react'
import { Autocomplete, Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'
import { fetchData } from '../../Api/useFetch'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddTeams = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [seriesList, setSeriesList] = useState([])
    const [currentSeries, setCurrentSeries] = useState('')
    const [teamName, setTeamName] = useState('')
    const [teamShortName, setTeamShortName] = useState('')

    const [startDate, setStartDate] = useState(new Date().getTime())
    const [endDate, setEndDate] = useState(new Date().getTime())
    const [quizName, setQuizName] = useState('')
    const [quizDescription, setQuizDescription] = useState('')
    const [quizTagline, setQuizTagline] = useState('')

    const handleSeriesList = (list) => {
        let teamArr = []
        list.forEach((data) => {
            teamArr.push(data.name)
        })
        setSeriesList(teamArr)
    }

    const getSeriesList = async (contestId) => {
        const data = await fetchData(`api/series`)
        console.log('Data : ', data)
        if (data) {
            setApiCalled(true)
            handleSeriesList(data.seriesList)
        }
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setQuizName('')
        setQuizDescription('')
    }

    const addNewSeries = () => {
        if (teamName === '') {
            setOpenErrorAlert(true)
            return
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: teamName, shortName: teamShortName, series: currentSeries, imageURL: quizDescription })
        }

        fetch(`${BASE_URL}/api/teams`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getSeriesList()
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                {' '}
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='text-4xl font-bold text-gray-500'> Add Team</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                disablePortal
                                options={seriesList}
                                value={currentSeries}
                                onChange={(event, newValue) => {
                                    setCurrentSeries(newValue || '')
                                }}
                                renderInput={(params) => <TextField {...params} placeholder='Series Name' id='combo-box-demo' sx={{ width: '50%' }} />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Team Name'
                                id='combo-box-demo'
                                value={teamName}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setTeamName(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Team Short Name'
                                id='combo-box-demo'
                                value={teamShortName}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setTeamShortName(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <TextField
                                disablePortal
                                placeholder='Team Flag URL'
                                id='combo-box-demo'
                                value={quizDescription}
                                sx={{ width: '50%' }}
                                onChange={(event) => {
                                    setQuizDescription(event.target.value)
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5 pb-12'>
                            <Button className='h-12' variant='contained' sx={{ width: '50%' }} color='secondary' onClick={addNewSeries}>
                                Add Team
                            </Button>
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default AddTeams
