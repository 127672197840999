import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'

import { useNavigate } from 'react-router-dom'
import { CardGiftcard, DashboardCustomizeSharp, DashboardRounded, DataArray, Logout, MilitaryTech, Person, SecurityUpdateSharp } from '@mui/icons-material'

import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useState } from 'react'

const Header = () => {
    const navigate = useNavigate()

    const handleRoute = (url) => {
        setAnchorElNav(null)
        navigate('/' + url)
    }

    const homeHandler = () => {
        setAnchorElNav(null)
        navigate('/')
    }

    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const [open, setOpen] = useState(false)

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen)
    }

    const contestListItems = [
        {
            value: 'Upcoming Contests',
            route: '',
            icon: <Person />
        },
        {
            value: 'Completed Contests',
            route: 'completed-contests',
            icon: <MilitaryTech />
        }
    ]

    const mainListItems = [
        {
            value: 'Add Player',
            route: 'players',
            icon: <Person />
        },
        {
            value: 'Add Contest',
            route: 'add-contest-v2',
            icon: <SecurityUpdateSharp />
        }
    ]

    const appDataItems = [
        {
            value: 'App Data',
            route: 'app-data',
            icon: <DataArray />
        },
        {
            value: 'Tickets',
            route: 'customer-tickets',
            icon: <DashboardCustomizeSharp />
        },
        {
            value: 'Rewards',
            route: 'reward-requests',
            icon: <CardGiftcard />
        }
    ]

    const DrawerList = (
        <Box sx={{ width: 400, paddingTop: 2, backgroundColor: '#f0f0f0', height: 1, }} role='presentation' onClick={toggleDrawer(false)}>
            <List>
                {contestListItems.map((text) => (
                    <ListItem key={text.value} disablePadding>
                        <ListItemButton onClick={() => handleRoute(text.route)}>
                            <ListItemIcon>{text.icon}</ListItemIcon>
                            <ListItemText primary={text.value} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {mainListItems.map((text) => (
                    <ListItem key={text.value} disablePadding>
                        <ListItemButton onClick={() => handleRoute(text.route)}>
                            <ListItemIcon>{text.icon}</ListItemIcon>
                            <ListItemText primary={text.value} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {appDataItems.map((text) => (
                    <ListItem key={text.value} disablePadding>
                        <ListItemButton onClick={() => handleRoute(text.route)}>
                            <ListItemIcon>{text.icon}</ListItemIcon>
                            <ListItemText primary={text.value} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                <ListItem key={12} disablePadding>
                    <ListItemButton
                        onClick={() => {
                            localStorage.clear()
                            handleRoute('login')
                        }}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    )

    return (
        <AppBar position='sticky'>
            <Container maxWidth='100%' sx={{ backgroundColor: '#3C4048', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar disableGutters>
                    <MenuIcon sx={{ display: { xs: 'none', md: 'flex', cursor: 'pointer' }, mr: 3 }} />
                    <Drawer open={open} onClose={toggleDrawer(false)}>
                        {DrawerList}
                    </Drawer>
                    <Typography
                        variant='h6'
                        noWrap
                        component='a'
                        href='/'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'roboto',
                            fontWeight: 700,
                            fontSize: 25,
                            letterSpacing: '.15rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}>
                        DASHBOARD
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' }
                        }}>
                        <IconButton size='large' aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={handleOpenNavMenu} color='inherit'>
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' }
                            }}>
                            <MenuItem key='Home' onClick={homeHandler}>
                                <Typography textAlign='center'>Home</Typography>
                            </MenuItem>
                            <MenuItem key='Home' onClick={() => handleRoute('live-contests')}>
                                <Typography textAlign='center'>Live Contests</Typography>
                            </MenuItem>
                            <MenuItem key='Home' onClick={() => handleRoute('completed-contests')}>
                                <Typography textAlign='center'>Completed Contests</Typography>
                            </MenuItem>
                            <MenuItem key='Home' onClick={() => handleRoute('add-contest-v2')}>
                                <Typography textAlign='center'>Add Contest V2</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    <DashboardRounded sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant='h5'
                        noWrap
                        component='a'
                        href=''
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none'
                        }}>
                        DASHBOARD
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' }
                        }}>
                        <Button
                            key='activity1'
                            onClick={() => handleRoute('add-contest')}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Add Quiz
                        </Button>
                        <Button
                            key='activity2'
                            onClick={() => handleRoute('completed-contests')}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Completed
                        </Button>
                        <Button
                            key='activity1'
                            onClick={() => handleRoute('players')}
                            sx={{
                                color: 'white',
                                display: 'block',
                                fontSize: '16px'
                            }}>
                            Players
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header
