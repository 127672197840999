import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import { BASE_URL } from '../../Api/Constants'
import { Box } from '@mui/system'
import { Stack, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { IPL_TEAMS, PLAYER_ROLES } from '../../Data/Teams'
import DrawerNav from '../DrawerNav/DrawerNav'
import { fetchData } from '../../Api/useFetch'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddPlayer = () => {
    const [playerName, setPlayerName] = useState('')
    const [teamName, setTeamName] = useState('')
    const [seriesName, setSeriesName] = useState('')
    const [correctScore, setCorrectScore] = useState(5)
    const [incorrectScore, setIncorrectScore] = useState(1)
    const [battingCorrectScore, setBattingCorrectScore] = useState(5)
    const [bowlingCorrectScore, setBowlingCorrectScore] = useState(5)
    const [battingIncorrectScore, setBattingIncorrectScore] = useState(1)
    const [bowlingIncorrectScore, setBowlingIncorrectScore] = useState(1)
    const [imageURL, setImageURL] = useState('')
    const [role, setRole] = useState('')
    const [apiCalled, setApiCalled] = useState(false)
    const [teamsList, setTeamsList] = useState([])
    const [seriesList, setSeriesList] = useState([])

    const handlePlayerNameChange = (event) => {
        setPlayerName(event.target.value)
    }

    const handleTeamNameChange = (event, value) => {
        setTeamName(value)
    }

    const handleSeriesNameChange = (event, value) => {
        setSeriesName(value)
        fetchTeamsNames(value)
    }

    const handleRoleChange = (event, value) => {
        console.log('Value : ', value)
        setRole(value)
    }

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')
    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const saveRewardState = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ player: playerName, team: teamName, series: seriesName, correctScore, incorrectScore, battingCorrectScore, battingIncorrectScore, bowlingCorrectScore, bowlingIncorrectScore, role, imageURL })
        }

        console.log('Request Options : ', requestOptions)

        fetch(`${BASE_URL}/api/admin/teams/players`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setPlayerName('')
                if (data.status === 200) {
                    setSuccessMessage('Player has been added')
                    setOpenSuccessAlert(true)
                } else {
                }
            })
    }

    const handleTeamsList = (list) => {
        let teams_list = []
        list.forEach((data) => {
            teams_list.push(data.name)
        })
        setTeamsList(teams_list)
    }

    const handleSeriesList = (list) => {
        let series_list = []
        list.forEach((data) => {
            series_list.push(data.name)
        })
        setSeriesList(series_list)
    }

    const fetchTeamsNames = async (series_name) => {
        const data = await fetchData(`api/teams?series=${series_name}`)
        console.log('Teams Name Data : ', data)
        if (data) {
            setApiCalled(true)
            handleTeamsList(data.teamsList)
        }
    }

    const getTeamsList = async (contestId) => {
        const seriesData = await fetchData(`api/series`)
        if (seriesData) {
            handleSeriesList(seriesData.seriesList)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTeamsList()
        }
    })

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <div className='text-center text-2xl font-bold mb-8'>Add New Player</div>
                    <div className='mt-4 w-4/5 flex flex-wrap m-auto gap-4 justify-center'>
                        <Autocomplete disablePortal id='series-name' options={seriesList} sx={{ width: '49%', cursor: 'pointer' }} onChange={handleSeriesNameChange} renderInput={(params) => <TextField {...params} label='Series Name' />} />
                        <Autocomplete disablePortal id='team-name' options={teamsList} sx={{ width: '49%', cursor: 'pointer' }} onChange={handleTeamNameChange} renderInput={(params) => <TextField {...params} label='Team Name' />} />
                        <TextField id='player-name' label='Player Name' sx={{ width: '49%' }} value={playerName} onChange={handlePlayerNameChange} />
                        <Autocomplete disablePortal id='player-role' options={PLAYER_ROLES} sx={{ width: '49%', cursor: 'pointer' }} onChange={handleRoleChange} renderInput={(params) => <TextField {...params} label='Player Role' />} />
                        <TextField id='correct-score' label='Default Correct Score' sx={{ width: '49%' }} value={correctScore} onChange={(e) => setCorrectScore(e.target.value)} />
                        <TextField id='incorrect-score' label='Default Incorrect Score' sx={{ width: '49%' }} value={incorrectScore} onChange={(e) => setIncorrectScore(e.target.value)} />

                        <TextField id='batting-correct-score' label='Batting Correct Score' sx={{ width: '49%' }} value={battingCorrectScore} onChange={(e) => setBattingCorrectScore(e.target.value)} />
                        <TextField id='batting-incorrect-score' label='Batting Incorrect Score' sx={{ width: '49%' }} value={battingIncorrectScore} onChange={(e) => setBattingIncorrectScore(e.target.value)} />

                        <TextField id='bowling-correct-score' label='Bowling Correct Score' sx={{ width: '49%' }} value={bowlingCorrectScore} onChange={(e) => setBowlingCorrectScore(e.target.value)} />
                        <TextField id='bowling-incorrect-score' label='Bowling Incorrect Score' sx={{ width: '49%' }} value={bowlingIncorrectScore} onChange={(e) => setBowlingIncorrectScore(e.target.value)} />
                        <TextField id='image-url' label='Image URL' sx={{ width: '99%' }} value={imageURL} onChange={(e) => setImageURL(e.target.value)} />
                        <Button className='h-12' variant='contained' color='success' sx={{ width: '100%', cursor: 'pointer' }} onClick={saveRewardState}>
                            Add Player
                        </Button>
                    </div>
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                {successMessage}
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    </Stack>
                </div>
            </Box>
        </Box>
    )
}

export default AddPlayer
