import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Button, Grid, Input, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { convertMillisecondsToDate, showTimeInAgoFormat } from '../../Utils/Time'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const ContestLeaderboard = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const location = useLocation()

    const [leaderboardData, setLeaderboardData] = useState([])
    const [currentContestId, setCurrentContestId] = useState('')
    const [awarded, setAwarded] = useState(true)
    const [entryFees, setEntryFees] = useState(0)
    const [totalReward, setTotalReward] = useState(0)

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const getLeaderboardOfContest = (contestId) => {
        console.log('Contest ID : ', contestId)
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/user/contest/leaderboard/${contestId}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setApiCalled(true)
                    setLeaderboardData(data.data.leaderboard)
                    setAwarded(data.data.awarded)
                    setEntryFees(data.data.entryFees)
                    setTotalReward(data.data.totalReward)
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        const path = location.pathname
        const contestId = path.substring(21)
        console.log('Contest Id : ', contestId)
        getLeaderboardOfContest(contestId)
        setCurrentContestId(contestId)
        window.scrollTo(0, 0)
    }, [])

    const distributeCoins = () => {
        setAwarded(true)
        const simplifiedData = leaderboardData.map(({ uid, coins }) => ({ uid, coins }))
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contestId: currentContestId, rewardList: simplifiedData })
        }

        fetch(`${BASE_URL}/api/admin/contest/reward`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <h1 className='text-2xl font-bold mt-5 text-gray-700'> Contest Leaderboard</h1>
                    <h1 className='text-2xl font-bold mt-5 text-gray-700'> Entry Fees - {entryFees} ⚫ Total Reward - {totalReward}</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5 pb-12'>
                    {awarded ? (
                        <Button className='h-12' disabled={true} variant='contained' sx={{ width: '50%' }} color='info'>
                            Coins already distributed
                        </Button>
                    ) : (
                        <Button className='h-12' variant='contained' sx={{ width: '50%' }} color='info' onClick={() => distributeCoins()}>
                            Distribute Coins
                        </Button>
                    )}
                </Grid>

                {apiCalled === true ? (
                    <>
                        <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Rank</StyledTableCell>
                                    <StyledTableCell align='left'>Name</StyledTableCell>
                                    <StyledTableCell align='left'>Email</StyledTableCell>
                                    <StyledTableCell align='left'>Last Active</StyledTableCell>
                                    <StyledTableCell align='left'>Score</StyledTableCell>
                                    <StyledTableCell align='left'>Coins</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {leaderboardData.map((data, index) => {
                                return (
                                    <TableBody key={index}>
                                        <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                            <StyledTableCell align='left'>{data.rank}</StyledTableCell>
                                            <StyledTableCell align='left'>{data.username}</StyledTableCell>
                                            <StyledTableCell align='left'>{data.email}</StyledTableCell>
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(data.lastActive)}</StyledTableCell>
                                            <StyledTableCell align='left'>{data.score}</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <TextField
                                                    id='combo-box-demo'
                                                    type='number'
                                                    value={data.coins}
                                                    InputLabelProps={{ shrink: true }}
                                                    sx={{ width: '100%' }}
                                                    onChange={(event, item) => {
                                                        const newCoins = event.target.value
                                                        console.log('New coins : ', newCoins)
                                                        setLeaderboardData((prevData) => {
                                                            const newData = [...prevData]
                                                            newData[index].coins = newCoins
                                                            return newData
                                                        })
                                                    }}
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )
                            })}
                        </Table>
                    </>
                ) : (
                    ''
                )}
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default ContestLeaderboard
