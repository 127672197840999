import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { formatDateToYYYYMMDD, showTimeInAgoFormat } from '../../Utils/Time'
import { Button, Card, CardContent, Snackbar, Stack, TextField, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import MuiAlert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { defaultModalStyle } from '../../Styles/Modal'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function EditContest() {
    const location = useLocation()
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        navigate(route)
    }

    const [open, setOpen] = React.useState(false)
    const [openEditModal, setOpenEditModal] = React.useState(false)
    //EditModal
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleOpenEditModal = (question) => {
        setQuestionData(question)
        setOpenEditModal(true)
    }

    const handleCloseEditModal = () => {
        setQuestionData({
            _id: '',
            question: '',
            answer: '',
            options: []
        })
        setOpenEditModal(false)
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const [contests, setContests] = useState([])
    const [apiCalled, setApiCalled] = useState(false)
    const [startDate, setStartDate] = useState(new Date().getTime())
    const [startDateInputValue, setStartDateInputValue] = useState(formatDateToYYYYMMDD(startDate))
    const [startTime, setStartTime] = useState('09:00')
    const [endDate, setEndDate] = useState(new Date().getTime() + 86400000 * 3)

    const [contestData, setContestData] = useState({
        _id: '',
        name: '',
        description: '',
        startDate: '',
        questions: []
    })

    const updateContestDetails = (property, value) => {
        setContestData((prevContestData) => ({
            ...prevContestData,
            [property]: value
        }))
    }

    const getMergedDate = (date, time) => {
        const startDate = date
        const startTime = time
        const startDateObject = new Date(startDate)
        const [hours, minutes] = startTime.split(':')
        startDateObject.setHours(hours, minutes, 0, 0)
        const mergedDateTime = startDateObject.getTime()
        return mergedDateTime
    }

    const getDateTimeFromMilliseconds = (milliseconds) => {
        const dateTimeObject = new Date(milliseconds)

        const date = dateTimeObject.toISOString().split('T')[0]
        const time = dateTimeObject.toTimeString().split(' ')[0]

        return { date, time }
    }

    const handleDate = (ms) => {
        const data = getDateTimeFromMilliseconds(ms)
        console.log('Data : ', data)
        setStartDateInputValue(data.date)
        setStartTime(data.time)
    }

    const handleContestTimeChange = () => {
        const mergedDate = getMergedDate(startDate, startTime)
        console.log('Merged Date : ', mergedDate)
        updateContestDetails('startDate', mergedDate)
    }

    const saveContestData = () => {
        handleContestTimeChange()
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: contestData.name, description: contestData.description, startDate: getMergedDate(startDate, startTime) })
        }

        fetch(`${BASE_URL}/api/admin/contest/${contestData._id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    getContestDetail(contestData._id)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const [questionData, setQuestionData] = useState({
        _id: '',
        question: '',
        answer: '',
        options: []
    })

    const updateQuestionData = (property, value, index = null) => {
        setQuestionData((prevData) => {
            const updatedOptions = [...prevData.options]
            if (index !== null) {
                updatedOptions[index] = value
            } else {
                updatedOptions.push(value)
            }
            return {
                ...prevData,
                [property]: index !== null ? updatedOptions : value
            }
        })
    }

    const getContestDetail = (contestId) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/admin/contest/${contestId}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setContestData(data.data.contest)
                    handleDate(data.data.contest.startDate)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const resetModalFields = () => {
        setQuestionData({
            question: '',
            answer: '',
            options: []
        })
    }

    const addQuestion = () => {
        handleClose()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contestId: contestData._id, question: questionData.question, answer: questionData.answer, options: questionData.options })
        }

        fetch(`${BASE_URL}/api/contest/question`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetModalFields()
                    getContestDetail(contestData._id)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const updateQuestion = () => {
        handleCloseEditModal()
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contestId: contestData._id, question: questionData.question, answer: questionData.answer, options: questionData.options })
        }

        fetch(`${BASE_URL}/api/contest/question/${questionData._id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetModalFields()
                    getContestDetail(contestData._id)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const handleDeleteQuestion = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contestId: contestData._id, question: questionData.question, answer: questionData.answer, options: questionData.options })
        }

        fetch(`${BASE_URL}/api/contest/question/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetModalFields()
                    getContestDetail(contestData._id)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const renderOptions = () => {
        const existingOptions = questionData.options.length
        const remainingOptions = Math.max(20 - existingOptions, 0)

        // Create an array with the required number of options
        const optionsArray = new Array(existingOptions + remainingOptions).fill('')

        return (
            <div className='flex flex-row gap-4 flex-wrap justify-center'>
                {optionsArray.map((_, index) => (
                    <TextField
                        key={index}
                        id={`outlined-controlled-option-${index}`}
                        label={`Option ${index + 1}`}
                        sx={{ width: '24%' }}
                        value={questionData.options[index] || ''}
                        onChange={(event) => {
                            updateQuestionData('options', event.target.value, index)
                        }}
                    />
                ))}
            </div>
        )
    }

    useEffect(() => {
        const path = location.pathname
        const contestId = path.substring(14)
        console.log('Contest Id : ', contestId)
        getContestDetail(contestId)
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='p-4'>
            {contestData.name.length > 0 && (
                <>
                    <Card key={'1'} className='my-2' sx={{ backgroundColor: 'whitesmoke' }}>
                        <CardContent>
                            <TextField
                                id='outlined-controlled'
                                label='Contest Name'
                                sx={{ width: '100%' }}
                                value={contestData.name}
                                onChange={(event) => {
                                    updateContestDetails('name', event.target.value)
                                }}
                            />
                            <TextField
                                id='outlined-controlled'
                                label='Contest Description'
                                sx={{ width: '100%', marginTop: '20px' }}
                                value={contestData.description}
                                onChange={(event) => {
                                    updateContestDetails('description', event.target.value)
                                }}
                            />

                            <TextField
                                label='Start Date'
                                type='date'
                                value={startDateInputValue}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '100%', marginTop: '20px' }}
                                onChange={(event, item) => {
                                    setStartDateInputValue(event.target.value)
                                    const myDate = event.target.value.split('-')
                                    const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime()
                                    setStartDate(newDate)
                                }}
                            />

                            <TextField
                                label='Start Time'
                                id='combo-box-demo'
                                type='time'
                                value={startTime}
                                InputLabelProps={{ shrink: true }}
                                sx={{ width: '100%', marginTop: '20px' }}
                                onChange={(event, item) => {
                                    setStartTime(event.target.value)
                                }}
                            />
                            <Button
                                variant='contained'
                                onClick={() => saveContestData()}
                                sx={{
                                    marginTop: '10px',
                                    marginRight: '10px',
                                    marginBottom: '20px',
                                    cursor: 'pointer',
                                    backgroundColor: 'green',
                                    '&:hover': {
                                        backgroundColor: 'darkgreen'
                                    }
                                }}>
                                Save
                            </Button>
                            <Typography variant='body2' color='text.secondary' key={11}>
                                Start Date: {showTimeInAgoFormat(contestData.startDate)}
                            </Typography>
                            <div></div>
                            <hr className='my-2' key={111} />
                            <div className='' key={1111}>
                                {contestData.questions &&
                                    contestData.questions.map((question, index) => {
                                        return (
                                            <>
                                                <div className='text-xl mt-4' key={index}>
                                                    {' '}
                                                    {index + 1}
                                                    {'. '}
                                                    {question.question}
                                                </div>

                                                <Button
                                                    variant='contained'
                                                    onClick={() => handleOpenEditModal(question)}
                                                    sx={{
                                                        marginTop: '10px',
                                                        marginRight: '10px',
                                                        cursor: 'pointer',
                                                        backgroundColor: 'darkgoldenrod',
                                                        '&:hover': {
                                                            backgroundColor: 'darkgoldenrod'
                                                        }
                                                    }}>
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    onClick={() => handleDeleteQuestion(question._id)}
                                                    sx={{
                                                        marginTop: '10px',
                                                        marginRight: '10px',
                                                        cursor: 'pointer',
                                                        backgroundColor: 'sienna',
                                                        '&:hover': {
                                                            backgroundColor: 'sienna'
                                                        }
                                                    }}>
                                                    Delete
                                                </Button>
                                                {question.answer === '' ? (
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => handleDeleteQuestion(question._id)}
                                                        disabled={true}
                                                        sx={{
                                                            marginTop: '10px',
                                                            marginRight: '10px',
                                                            cursor: 'pointer',
                                                            backgroundColor: 'sienna',
                                                            '&:hover': {
                                                                backgroundColor: 'sienna'
                                                            }
                                                        }}>
                                                        Empty Answer
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant='contained'
                                                        onClick={() => handleDeleteQuestion(question._id)}
                                                        disabled={true}
                                                        sx={{
                                                            marginTop: '10px',
                                                            marginRight: '10px',
                                                            cursor: 'pointer',
                                                            backgroundColor: 'sienna',
                                                            '&:hover': {
                                                                backgroundColor: 'sienna'
                                                            }
                                                        }}>
                                                        Answer Available
                                                    </Button>
                                                )}
                                                <hr className='my-2' />
                                            </>
                                        )
                                    })}
                            </div>
                            <div>
                                <Button variant='contained' color='info' onClick={() => handleOpen()} sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                    Add Question
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </>
            )}
            {contestData.name.length === '' && <div className='mt-10 text-center'>No Contest Details</div>}
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}>
                <Fade in={open}>
                    <Box sx={defaultModalStyle}>
                        <div className='flex flex-col gap-6 justify-center'>
                            <h1 className='text-center text-2xl font-bold text-gray-700'>Add Question</h1>
                            <TextField
                                id='outlined-controlled'
                                label='Question'
                                sx={{ width: '100%' }}
                                value={questionData.question}
                                onChange={(event) => {
                                    updateQuestionData('question', event.target.value)
                                }}
                            />
                            <TextField
                                id='outlined-controlled'
                                label='Answer'
                                sx={{ width: '100%' }}
                                value={questionData.answer}
                                onChange={(event) => {
                                    updateQuestionData('answer', event.target.value)
                                }}
                            />
                            {renderOptions()}
                            <Button variant='contained' color='success' onClick={() => addQuestion()} sx={{ width: '100%', cursor: 'pointer' }}>
                                Add Question
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={openEditModal}
                onClose={handleCloseEditModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}>
                <Fade in={openEditModal}>
                    <Box sx={defaultModalStyle}>
                        <div className='flex flex-col gap-6 justify-center'>
                            <h1 className='text-center text-2xl font-bold text-brown-700'>Edit Question</h1>
                            <TextField
                                id='outlined-controlled'
                                label='Question'
                                sx={{ width: '100%' }}
                                value={questionData.question}
                                onChange={(event) => {
                                    updateQuestionData('question', event.target.value)
                                }}
                            />
                            <TextField
                                id='outlined-controlled'
                                label='Answer'
                                sx={{ width: '100%' }}
                                value={questionData.answer}
                                onChange={(event) => {
                                    updateQuestionData('answer', event.target.value)
                                }}
                            />
                            {renderOptions()}
                            <Button variant='contained' color='info' onClick={() => updateQuestion()} sx={{ width: '100%', cursor: 'pointer' }}>
                                Update
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </div>
    )
}

export default EditContest
